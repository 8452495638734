import "./Cookies.css";
import instance, { APP_COLORS, APP_IMAGES } from "../common/Api";

import React, { useEffect, useState } from "react";

const Privacy = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    instance.get(`/getPage/3`).then((response) => {
      const data = response.data;
      console.error("Error fetching data:", data.description);
      setHtmlContent(data.description);
    });
  }, []);

  return (
    <div
      className="container-fluid privacyPolicyImgbox"
      style={{
        width: "100%",
        minHeight: "51rem",
        position: "relative",
        backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_WEB})`,
        backgroundSize: "cover",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="container privacyPolicyBellowTextBox">
        <div id="scroll">
          <div className="row">
            <div className="col-md-12 privacyContentBox">
              <h6
                style={{
                  color: APP_COLORS.APP_DARK_1,
                  paddingTop: "50px",
                  paddingBottom: "10px",
                }}
              >
                1. INTRODUCTION
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                ‘Niovi Digital Ltd.’ (referred as ‘We’, ‘Ours’, or ‘Us’) is the
                owner & operator of https://www.chorchuri.com( referred to as
                “Website”). We respect Your privacy and recognize the need to
                protect Your Personal data and Information shared with Us. The
                Data /Personal Data /Information We collect will vary depending
                upon how You use Our Website. Niovi Digital Ltd., is a company
                having its registered office at 10 Barnetts Court,Harrow,HA2
                8EU. If You have any question, comment and /or request regarding
                this Privacy Policy or wish to obtain more details on Your
                Personal Data We process, You may please contact Us at
                help@chorchuri.com This Privacy Policy provides an overview of how
                We collect and process Your Personal data /information and
                describe about Your rights under the EU General Data Protection
                Regulation (“GDPR”)
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                2. APPLICABLITY
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                The contents of this Privacy Policy (this “Privacy Policy) along
                with the Terms and Conditions (“Terms and Conditions”) available
                at [https://www.chorchuri.com/terms-conditions] are applicable
                (without limitation) to all hyperlinks on the ‘Website’ which
                can be accessed at [https://www.chorchuri.com] (“Website”). All
                ‘Services’ provided by ‘Website’ will be governed by this
                Privacy Policy. This terms of Privacy Policy is applicable to
                the Visitor(s) who accesses or browse the Website, in any manner
                (‘Visitors’) and those who register or log in the Website
                (‘Subscribers’). The words 'You' or 'Your' or 'User' as used
                herein, refer to the Visitor(s) as well as the Subscriber(s),
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                3. ACKNOWLEDGMENT AND CONSENT
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                By accessing and browsing the Website, in any manner, You
                represent, agree, acknowledge, and consent that You are eighteen
                (18) years of age or You have attained the age of majority as
                per the laws of Your state/province/residence/jurisdiction.
                Persons who don’t meet this criteria are strictly forbidden from
                accessing or viewing the contents of the Website. We don’t
                knowingly seek or collect any Personal information from the
                persons who have not attained the age of majority. Further, by
                accessing and browsing the Website, and by registering or
                logging in the Website, You hereby acknowledge to have
                thoroughly read, understood, and agreed to be bound by the terms
                of this Privacy Policy and affirm, assent and concede to the
                collection, use, storage, processing and disclosure of Your
                Personal Information /Data as per the terms of this Privacy
                Policy. If You don’t agree to the terms of this Privacy policy,
                You should not register or avail Our ‘Services’. You can revoke
                your consent at any time by expressly writing to Us. However,
                any processing of Personal Data prior to the receipt of Your
                revocation will not be affected.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                4. DEFINITIONS
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                The term or word: “Information” includes ‘Personal Information
                or Personal Data’ “Personal data” or “Data” includes ‘Personal
                Information” or “Personal Data’ “Processing” whenever referred
                means handling of Your Personal Data by Us, including
                collecting, protecting, and storing Your Personal Data.
                “Sensitive Data” mean Personal Data which may reveal information
                about racial or ethnic origin, political opinions, religious
                beliefs, trade union activities, physical or mental health,
                sexual life, genetic or biometric data. “Personal
                Information/Data” shall mean any document/information You
                provide to Us and shall include, without limitation, ‘your
                name’, ‘phone no’, ‘Your address’, ‘date of birth’, ‘email
                address’, ‘gender’, ‘photograph’, ‘occupation’, or credit card
                or debit card or net banking, other payments details, Your
                identity proof document number, addressee proof document no,
                details from data base, social media activity, log in id,
                password and similar other information.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                5. USE OF SERVICES / SUBCRIPTION /USER ACCOUNT
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                To use all the services on the Website or to subscribe, You will
                need to register using the online registration form and create
                an account (“Account”). When browsing on the Website, You are
                not required to provide any Personal Information unless and
                until You choose to make an Account on the Website. Personal
                Information about a user is an integral part of Our business.
                The Personal Information of each Account is protected by a
                password. The User and We will take all measures to prevent any
                unauthorized access to the Account and the Personal Information.
                We endeavour to safeguard User information to ensure that
                Personal Information of each Account is kept private; however,
                We cannot guarantee the security of User Account information. We
                will not share Your Personal Information with any third party
                without Your explicit consent or except as provided under this
                Privacy Policy.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                6. WHAT WE COLLECT
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                We collect Your information or Personal data to provide You
                better experience and services. We collect following information
                or Personal data of Users (“information” or “Data” or “personal
                data”): <br />
                A. When You Browse, We collect and process following Personal
                Data from Browsers:
                <br />
                1. We collect and process the information such as IP Address,
                Your browser type and version, time zone setting, and location,
                browser plug in types and version, smart device information,
                mobile phone network information, operating system and platform
                and other technology on the devices You use to access this
                website;
                <br />
                2. We collect and process Your communication preferences and
                information about how You use Our Website including the services
                You viewed or Searched for, page response times, errors, length
                and number of visits and page interaction information (such as
                scrolling, clicks and mouse hovers) as well as the links You
                clicked on or used.
                <br />
                3. We also collect and process the User submitted Data including
                the data collected from specific function, for instance a
                contest or a survey; and Your consents, permissions, or
                preferences that You give to the Website.
                <br />
                <br />
                B. When You Subscribe /log in, We collect and process following
                Personal Data:
                <br />
                1. Your Personal information provided at the time of
                registration including username or similar identifier, names,
                address, mailing address, telephone number, email address,
                financial information. Also, Your photo or video, if such is
                uploaded to Your profile.
                <br />
                2. Any other data submitted by You, including data collected at
                Your discretion for a specific function, Your interest,
                preferences, feedback, survey responses, or any text, image,
                video, if any uploaded on Website.
                <br />
                3. When You are conducting financial transaction, We or Payment
                gateway or Partner website (‘Third Party’ or ‘Third Party
                Website’) may collect information such as Your net banking id,
                debit card /credit card details, ‘Your name’, ‘phone no’, ‘date
                of birth’, ‘email address’, wallet details, UPI id’s, or any
                other payment details/methods.
                <br />
                4. Your information may be collected through cookies as provided
                herein below.
                <br />
                <br />
                All other data mentioned in Clause 6(A)(1).
                <br />
                <br />
                We may also collect Personal Information on the Website by
                information filled in forms or by Your correspondences with Us
                by way of e-mail or chat, information provided while registering
                to use the Website, subscribing to any services or products
                offered on the Website. While using the services available on
                the Website, We may also store information based on the usage
                history; this includes, but is not limited to, details of Your
                subscription, content viewed, event information, clickstream
                information, and Cookies that may uniquely identify the browser
                or the Account. We may also collect information about You from
                any contact that You may have with any of Our or employees, such
                as, with Our customer support team, in surveys, or through
                interactions with Our affiliates
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                7. HOW WE USE YOUR INFORMATION
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                We use Your information or Personal data:
                <br />
                <br />
                1. To provide Our services and service related purpose such as
                responding to Your queries, and carrying out the transactions
                You have requested for; enabling You to use the Website;
                providing You information about services available through
                Website; Resolving glitches on the Website including addressing
                any technical problem;
                <br />
                2. Maintain and improve Our services such as navigating Website,
                and carrying out transaction on Website.
                <br />
                3. To communicate or interact with You (Email add, Phone Nos.
                etc.)
                <br />
                4. Improve safety and reliability of Our services.
                <br />
                5. Reconcile Bank statement.
                <br />
                6. To prevent fraud and misuse.
                <br />
                7. To protect our interest, interest of other users.
                <br />
                8. Enforcing our rights and interest.
                <br />
                9. For compliance of legal obligations.
                <br />
                10. For Internal business purpose, such as Data analysis,
                research, developing new features, enhancing and improving
                experience, identifying usage trends etc.
                <br />
                11. We develop statistics that are helpful to understand how You
                can use the Website. We use this information in the aggregate to
                measure the use of the Website and to administer and improve the
                same. This statistical data is interpreted by Us in Our
                continuing effort to present on the Website, content that You
                are seeking in a format You find most helpful. The information
                We collect and analyze is completely anonymous and is used to
                improve the services offered on the Website.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                8. SHARING OF INFORMATION
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                We may disclose the information /personal data in following
                ways:
                <br />
                1. We will share the Personal Information internally with Our
                employees who are required in the course of business (and solely
                for the purpose of) to receive and analyse the information. We
                will not disclose the Personal Information to any third party
                without Yours explicit consent unless required to do so by law.
                <br />
                2. If We are sold to a third party, or We otherwise becomes
                subject to a merger, consolidation or acquisition, We will
                transfer the Personal Information to such third party.
                <br />
                3. We may also use the Personal Information to deliver
                information to You, in some cases, targeted towards new
                services, rewards and promotions.
                <br />
                Following are some circumstances under which and people with
                whom We will share the Personal information from time-to-time:
                <br />
                1. to update Subscriber’s database;
                <br />
                2. with trusted partners who work with us under confidentiality
                agreements; these entities may use the Personal Information to
                help communicate with You about Our offers, and Our marketing
                partners;
                <br />
                3. with companies and/or individuals to perform functions on Our
                behalf (e.g. processing payments/financial transactions,
                providing user service, data hosting services), and in such
                circumstances these companies or individuals will be provided
                access to only such Personal Information as are required to
                fulfil their functions;
                <br />
                4. to respond to summons, court orders, or legal process, or to
                establish or exercise our legal rights or defend against legal
                claims;
                <br />
                5. to share Personal Information in order to investigate,
                prevent, or take action regarding illegal activities, suspected
                fraud, situations involving potential threats to the physical
                safety of any person, violations of the Terms and Conditions, or
                to respond to civil or criminal subpoenas, or other requests by
                law enforcement personnel or as otherwise required by law;
                <br />
                6. from time to time, to reveal general statistical information
                about the Website, Visitors and Subscribers, such as number of
                visitors, number of Subscribers, number and type of services
                purchased, etc;
                <br />
                7. with third parties such as internal service providers that We
                may use to facilitate or outsource one or more aspects of the
                business, product and service operations; however, You may note
                that these internal service providers will be subject to
                confidentiality agreements with Us; and
                <br />
                8. with government agencies mandated under the law to obtain
                information including sensitive personal data or information for
                the purpose of verification of identity, or for prevention,
                detection, investigation including cyber incidents, prosecution,
                and punishment of offences.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                9. PROTECTION OF INFORMATION/PERSONAL DATA
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                1. We take all steps reasonably necessary to protect against the
                unauthorized access, use, alteration, disclosure or destruction
                of Personal Information. Any Personal Information that We
                collect and store is protected using appropriate means. Although
                We are committed to preventing others from unauthorized access
                to the Personal Information, We will not be held responsible for
                any unauthorized access by third parties.
                <br />
                2. We assure You that Your information is safe and secure. We
                use industry standards, physical, technical and administrative
                security measures to keep Your Personal information safe.
                <br />
                3. We store data in password protected servers with limited
                access to the employees and other personnel consultants on need
                to know basis.
                <br />
                4. In order to make every effort to ensure that the Visitor’s
                /Subscriber’s experience at the Website is secure, We use
                encryption technology to protect the Visitor/Subscriber against
                the loss, misuse or alteration of the Personal Information.
                <br />
                5. Your payment information such as net banking, etc. are
                protected through use of encryption technology.
                <br />
                6. Access to Your online account on the Website is password
                protected, You are solely responsible for maintaining the
                confidentiality of that password. To ensure safety of Your
                personal information, You are advised against sharing Your user
                name and password with any one.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                10. INTERNATIONAL TRANSFERS
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                Our business may require transfers of personal information
                between associates, subsidiary, sister concerns, as well as to
                third parties where we do business or avail their services.
                These third party may be based outside European Economic Area
                (EEA). While processing Your personal data to these third
                parties We ensure similar degree of protection as it employed to
                the data processed by Us. We further ensure the following
                safeguards:
                <br />
                1. Where required We implement Standard Contractual Clauses
                approved by EU Commission or similar contractual clauses in
                other jurisdiction. This includes transfer to the Suppliers or
                other third parties.
                <br />
                2. Where We use Suppliers /third parties /providers in US, We
                may transfer data to them if they are part of the Privacy Shield
                which requires them to provide similar protection to the
                Personal Data shared between the European Union and the US.
                <br />
                3. We may additionally, in rare occasions, transfer Your
                Personal Data to a party outside EEA where We have Your prior
                explicit consent do so or where such transfer is necessary for
                the provision of Our Services to You.
                <br />
                4. We may also transfer your Personal Data on need basis to Our
                subsidiaries or sister companies outside UK.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                11. MARKTING INFORMATION /OPTING OUT
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                We may process Your personal data so as to inform You about
                existing /future services by sending You periodic announcements.
                You have right to object at any time to the processing of Your
                Personal Data for marketing purposes by clicking on the opt-out
                link given at the bottom of marketing emails We send to You. We
                provide all Users with the opportunity to opt-out of receiving
                non-essential (promotional, marketing-related) communications.
                If You do not want to receive commercial email or other mail
                from Us, You can opt-out of receiving information by using the
                'unsubscribe' option in any marketing e-mail or send an email at
                help@chorchuri.com
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                12. RETENTION OF PERSONAL DATA
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                We may keep the Personal Information for only as long as
                required to fulfil the purpose for which it was collected,
                including for the purposes of satisfying any legal accounting or
                reporting requirements. We may retain Your personal Data for
                longer period in the event of a complaint or if We reasonably
                believe there is a prospect of litigation in respect of Our
                relationship with You.
                <br />
                The amount, nature and sensitivity of Your Personal Data, the
                potential risk of harm from unauthorised use or disclosure of
                Your Personal Data, the purpose for which We process Your
                Personal Data and whether We can achieve those purposes through
                other means and applicable legal requirement shall also be basis
                of determination of the retention period. Where We no longer
                need to process Your Personal Data, We will delete the same from
                Our system. However, We may keep Your data for longer if We
                cannot delete it for legal, regulatory or technical reasons. If
                We do, We will make sure that Your privacy is protected and that
                Your data are only used for those purposes.
                <br />
                For any query regarding data retention practices, please contact
                Us by sending email at help@chorchuri.com
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                13. YOUR DATA PROTECTION RIGHTS
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                Our full endeavour is to make You aware about Your rights in
                relation to Your Personal data We process. You may exercise
                following rights /option:
                <br />
                1. You can get access or receive a copy of Your Personal Data
                that We Hold and to check that We are lawfully processing it.
                <br />
                2. If You believe that Your information We hold is inaccurate or
                incomplete, You can correct it or have right to request that We
                correct such inaccurate Personal information.
                <br />
                3. You may request that We delete Your personal information if
                You belief that : <br />
                (a) We no longer need to process Your information for the
                purpose for which it was provided, <br />
                (b) You wish to withdraw Your content for permission to process
                Your personal information, or (c) We are not using Your
                information in lawful manner.
                <br />
                4. If You don’t want to use our services, You always have the
                option to delete Your account with Us.
                <br />
                5. However, You should understand that as a result of deleting
                Your personal information, You will lose access to most of Our
                Services. Further, there is a grace period of 45 days before We
                proceed for erasure of Your personal data pursuant to your
                request. During the grace period You will be allowed to restore
                your account. The grace period functionality allows You not to
                lose any important information and options by mistake. After the
                expiration of the grace period, however Your account will be
                deleted.
                <br />
                6. You have the right to object the processing of Your Personal
                data where We are relying on a legitimate interest and there is
                something about Your particular situation which makes You want
                to object to processing on this ground. If You exercise Your
                right to object, We will no longer process Your personal data
                unless We can demonstrate compelling legitimate ground for the
                processing which override Your interest, rights and freedom or
                for the establishment, exercise and defense of legal claims. If
                You object to processing for direct marketing purposes, then We
                will stop the processing of Your Personal data for such
                purposes. Depending upon circumstances, We may need to restrict
                or cease processing Your Personal data altogether or, where
                requested, delete Your Personal Data. If You object Us
                processing Your personal data, We may have to suspend the
                Services We provide to You.
                <br />
                7. You may request for restriction of processing Your personal
                data if, (a) it is not accurate; or (b) it has been used
                unlawfully but You don’t want to delete it; or (c) it is not
                relevant any more, but You want us to keep it for use in the
                possible legal claims; or (d) You have already asked us to stop
                using Your Personal data but You are waiting for us to confirm
                if We have legitimate grounds to use Your Personal Data. If You
                request restriction of processing Your personal data, We may
                have to suspend the Services We provide to You.
                <br />
                8. When We have requested Your permission to process Your
                Personal Data or You have provided Us with information for the
                purposes of entering into a contract with You, You have the
                right to receive the personal data You provided Us in a portable
                format. You may also request Us to provide it directly to a
                third party, if technically feasible. We are not responsible for
                any such third party use of Your account information, which will
                be governed by their agreement with You and any privacy
                statement they provided to You. Please note that some request to
                delete certain personal data will require deletion of Your
                account as a subscriber as the provision of Subscriber Account
                is inextricably linked to use of certain personal data (e.g.
                Your email address). Also note that it is possible that We
                require additional information from You in order to verify Your
                authorisation to make the request and to honor Your request. To
                exercise any of Your rights, or if You have any other questions
                about Our use of Your Personal data, please contact us by
                sending email at help@chorchuri.com
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                14. REVIEW & UPDATE OF YOUR PERSONAL DATA
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                When You are signed in, You can always access, modify, review,
                and update information provided during the registration process.
                You can also review and control information saved in Your
                account. If You don’t want to use our services, You have always
                option to delete Your account with Us.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                15. RIGHT TO COMPLAIN
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                If You have any questions about this Privacy Policy or wish to
                lodge a complaint about how Your Personal data is used by Us,
                You may contact us by email at help@chorchuri.com. Upon receipt of
                Your complaint, We will investigate and respond to You within
                reasonable time. You also have the right to complain to the Data
                Protection supervisory authority of the country in which You
                reside, Provided that such country is a Member state of the
                European Union or is located within EEA.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                16. POLICY TOWARDS MINORS
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                Our Website is not directed to individuals under the age of
                eighteen (18) or below the applicable age of minority in the
                jurisdiction form which the Website is accessed and We don’t
                knowingly collect Personal data from minors. If You become aware
                that Your Child has provided us with Personal data, please
                contact Us immediately by email at help@chorchuri.com. If We become
                aware that a minor has provided Us with Personal Data, We will
                take steps to remove such information and terminate that
                person’s account immediately.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                17. THIRD PARTY WEBSITE /SERVICE SITE
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                When You click on a Service Site/Third Party website or Link,
                You will be entering another website over which We have no
                control and will bear no responsibility. Often these websites
                including the payment gateway website require You to enter Your
                Personal Information. We encourage You to read the privacy
                statements on all such websites.
                <br />
                <br />
                You agree that, for any kind of breach of Privacy or loss caused
                thereby due to your use or visit to Third Party website /Service
                sites, We will not be liable. The terms of this Privacy Policy
                governs only the information processed or collected by the
                Website.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                18. CHANGES /AMENDMENT
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                We may amend this Privacy Policy from time to time without
                requiring any consent from You/anyone. Use of information We
                collect is subject to the Privacy Policy in effect at the time
                such information is used. If We change this Privacy Policy, the
                same will be posted on this page so that You are aware of the
                Personal Information collected, used and disclosed by Us. Any
                changes to the Privacy Policy will be communicated through the
                Website. You are bound by any changes to the Privacy Policy when
                You use after such changes have been first posted.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                19. USE OF COOKIES
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                The Website uses cookies (“Cookies”). A cookie is a small text
                file, that may be used, for example, to collect information
                about Your website activity. By accepting the terms of this
                Privacy Policy, You confirm and You consent for Website usages
                of Cookies. If You do not wish to accept Cookies from the
                Website, You can configure Your web browser so that it does not
                accept Cookies; however, by doing so certain features of the
                Website may be inaccessible by You. To find out more about how
                We use Cookies, please see our Cookie policy at
                http://www.chorchuri.com/cookie-policy.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                20. LIMITATIONS OF LIABILITY
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                We are not responsible for any breach of security or for any
                actions or inactions of any third party that receive Your
                personal information. Notwithstanding anything contained in this
                Policy or elsewhere, We shall not be held responsible for any
                loss, damage or misuse of Your Personal Information, if such
                loss, damage or misuse is attributable to a Force Majeure Event.
                A “Force Majeure Event” shall mean any event that is beyond Our
                reasonable control and shall include, without limitation,
                sabotage, fire, flood, explosion, acts of God, civil commotion,
                strikes, lockouts or industrial action of any kind, riots,
                epidemic, insurrection, war, acts of government, computer
                hacking, civil disturbances, unauthorised access to computer
                data and storage device, computer crashes, breach of security
                and encryption, and any other similar events not within Our
                control and which We are not able to overcome.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                21. INDEMNITY
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                You undertake to indemnify and keep Us (and /or Ours Partner,
                Shareholders Directors, Employees, Investors, Agents, etc)
                harmless, and any other third party who may be relying on the
                information provided by You and in the event You are in breach
                of this policy.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                22. SEVERABILITY
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                If at any time during the currency of this Privacy Policy, any
                provision, condition, term, stipulation, covenant or undertaking
                of this instrument is or becomes illegal, void, invalid,
                prohibited or unenforceable in any respect, the same shall be
                ineffective to the extent of such illegality or enforceability
                without invalidating in any manner whatsoever the remaining
                provisions here of or there of.
              </p>
              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                23. INTELLECTUAL PROPERTY RIGHTS
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                We own all rights in the mark chorchuri. All rights in respect of
                this trademark are hereby expressly reserved. Unless otherwise
                indicated, all other trademarks appearing on the Website are the
                property of their respective owners.
              </p>

              <p style={{ color: APP_COLORS.APP_WHITE }}>
                ‘Niovi Digital Ltd.’ is the owner & operator of
                https://www.chorchuri.com (referred to as “Website”). The Purpose
                of this document is for you to read and duly understand the
                terms of this Policy (“Cookies’ Policy” or “Policy”).
                <br />
                This Website uses cookies and other similar tracking
                technologies (“Cookies”), which collect information about your
                browsing activities.
                <br />
                Cookies or similar tracking technologies help us to provide you
                best possible website browsing experience and also to improve
                the Website and our services.
                <br />
                <br />
                By browsing the Website, it shall be deemed that you have
                thoroughly read, understood; and acknowledge, agree and consent
                to the terms of this Cookies’ Policy.
                <br />
                Further, by doing so, you also confirm and consent for the
                Website’s usage of cookies and other such tracking technologies.
                <br />
                <br />
                If you don’t wish to accept cookies from the Website, you can
                configure your web browser so that it does not accept cookies;
                however, <br />
                by doing so certain features of the Website may be inaccessible
                to you.
              </p>

              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                This Cookies’ Policy explains:
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                How we use cookies or other similar tracking software.
                <br />
                Your choices as to whether we can put cookies on your computer
                or mobile, tablet and other similar handheld device (“Computer
                or device”).
                <br />
                The term “Personal Data” as used in this Policy mean the data
                which identifies you as an individual and may include your name
                or online identifiers such as your Browser type and IP Address.
              </p>

              <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>
                {" "}
                1. What are Cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                A “cookie” is a small text file. When you visit the website or
                use an application, the website/application sends cookies to
                your computer or device. Your computer or device stores cookies
                in a file located inside your web borrower.
                <br />
                Different types of cookies keep track of different activities.
                The same have been explained in Clause 4 hereinbelow.
              </p>

              <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>
                {" "}
                2. How we use cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                The purpose of the cookies is to help the Website keep track of
                your visits and activities. These cookies allow us to recognise
                users, tailor the contents of our Website to fit the needs of
                Website Visitors, and improve user’s experience. These cookies
                allow us to keep a record of your most recent visit or to record
                your log in information; to obtain information about your visits
                and about the device you use to access the Website; your IP
                address, pseudonymous identifiers, operating system, browser
                type, and depending on the cookie, also include the reporting of
                statistical data about our users’ browsing actions and patterns.
              </p>
              <h1
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                Cookie's Policy
              </h1>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                ‘Niovi Digital Ltd.’ is the owner & operator of
                https://www.chorchuri.com (referred to as “Website”). The Purpose
                of this document is for you to read and duly understand the
                terms of this Policy (“Cookies’ Policy” or “Policy”).
                <br />
                This Website uses cookies and other similar tracking
                technologies (“Cookies”), which collect information about your
                browsing activities.
                <br />
                Cookies or similar tracking technologies help us to provide you
                best possible website browsing experience and also to improve
                the Website and our services.
                <br />
                <br />
                By browsing the Website, it shall be deemed that you have
                thoroughly read, understood; and acknowledge, agree and consent
                to the terms of this Cookies’ Policy.
                <br />
                Further, by doing so, you also confirm and consent for the
                Website’s usage of cookies and other such tracking technologies.
                <br />
                <br />
                If you don’t wish to accept cookies from the Website, you can
                configure your web browser so that it does not accept cookies;
                however, <br />
                by doing so certain features of the Website may be inaccessible
                to you.
              </p>

              <h6
                style={{ color: APP_COLORS.APP_DARK_1, paddingBottom: "10px" }}
              >
                This Cookies’ Policy explains:
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                How we use cookies or other similar tracking software.
                <br />
                Your choices as to whether we can put cookies on your computer
                or mobile, tablet and other similar handheld device (“Computer
                or device”).
                <br />
                The term “Personal Data” as used in this Policy mean the data
                which identifies you as an individual and may include your name
                or online identifiers such as your Browser type and IP Address.
              </p>

              <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>
                {" "}
                1. What are Cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                A “cookie” is a small text file. When you visit the website or
                use an application, the website/application sends cookies to
                your computer or device. Your computer or device stores cookies
                in a file located inside your web borrower.
                <br />
                Different types of cookies keep track of different activities.
                The same have been explained in Clause 4 hereinbelow.
              </p>

              <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>
                {" "}
                2. How we use cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                The purpose of the cookies is to help the Website keep track of
                your visits and activities. These cookies allow us to recognise
                users, tailor the contents of our Website to fit the needs of
                Website Visitors, and improve user’s experience. These cookies
                allow us to keep a record of your most recent visit or to record
                your log in information; to obtain information about your visits
                and about the device you use to access the Website; your IP
                address, pseudonymous identifiers, operating system, browser
                type, and depending on the cookie, also include the reporting of
                statistical data about our users’ browsing actions and patterns.
              </p>

              <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>
                {" "}
                3. Why we use cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                We deploy cookies to:
              </p>
              <p style={{ paddingLeft: "40px", color: APP_COLORS.APP_WHITE }}>
                1. Provide online services more suited to computer or device you
                connect; and /or
                <br />
                2. To prevent and detect fraud and keep your connection secure;
                and /or
                <br />
                3. When you visit our Website from any computer or device, we
                collect information about your use of Website, such as
                information about the device or browser you use to access the
                Website (including device type, operating system, screen
                resolution etc.), the way you interact with this Website, and
                the IP Address your computer or device connect from. You may not
                be able to initiate or complete some activities with our online
                services unless cookies or similar technologies are installed.
                <br />
                4. We also use cookies to record your most recent visit or to
                record your log in information, which is useful for you to know
                that why you have visited or downloaded in past. We also use
                cookies (and other similar technologies) to:
                <p style={{ paddingLeft: "40px", color: APP_COLORS.APP_WHITE }}>
                  1. Provide products and services that you request and to
                  provide a secure online environment; and /or
                  <br />
                  2. Manage our marketing relationship; and /or
                  <br />
                  3. Give you a better online experience and track website
                  performance; and /or
                  <br />
                  4. Provide products and services that you request and to
                  provide a secure online environment; and /or
                  <br />
                </p>
              </p>

              <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>
                {" "}
                4. Cookies Categories
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                This clause explains each cookie categories in more detail. The
                length of time a cookie stays on your computer or device depends
                on its type. We use following types of cookies on our Website{" "}
                <br />
                Session cookies:
                <br />
                These are the temporary cookies which only exist, during the
                time you use the Website (or more strictly, until you close the
                relevant browser). Session cookies help our website remember
                what your chose in the previous page, avoiding the need to
                re-enter information and improve your experience whilst using
                the Website.
                <br />
                Persistent cookies:
                <br />
                These are the cookies which stay on your computer or device
                after you have closed your browser and remain in your browser’s
                sub-folders until they expire or you delete them. These cookies
                can further be sub-divided into following categories:
                <br />
                Authentication cookies:
                <br />
                These cookies enable a user to log into our website and
                recognize such user when navigating website. These cookies are
                the essential cookies and used to keep the Website secure.
                <br />
                Functionality cookies:
                <br />
                These cookies remember your preferences and tailor the Website
                to provide enhanced feature or personalised online experience.
                These cookies enable (a) log in faster by recognising you if you
                are logged into the Website, and (b) remember the relevant
                information as you browse from page to page to save you
                re-entering the same information repeatedly.
                <br />
                Security cookies:
                <br />
                These cookies maintain online security and protect against
                malicious activities and /or violation of our User Agreement.
                <br />
                Analytics cookies:
                <br />
                These cookies are used to understand and analyse how you use and
                explore the Website and look for ways to improve it. These can
                also be used to test new advertisements, pages, or features to
                gauge user/s reaction. These Analytics cookies may be our own or
                third party cookies and include Google Analytics.
              </p>

              <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>
                {" "}
                5. Third Parties cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                Third Parties may provide certain contents or application
                including advertisements on our website. To provide such
                services or functionalities these Third Parties may use cookies
                (alone or in conjunction with web beacons or other tracking
                technologies) to collect information about you and about your
                use of the Website, on which we have no control. Unless
                expressly stated otherwise or elsewhere, We do not provide any
                personal data to these Third Parties, however, they may collect
                information, including personal data such as IP Address, browser
                type, version, time zone setting, and location, operating system
                and platform and other technology on the devices you use to
                access the Website. They may use those information to provide
                you internet-based advertising or other targeted content. We
                strongly advise and recommend you to check Privacy and Cookies
                Policies of those Third Party Websites for information about the
                cookies they may use and collection of the personal data. We
                don’t accept any responsibility for any content contained in
                these Third Party Websites.
              </p>

              <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>
                {" "}
                6. You may please note
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                You have option to delete or turning off one of more types of
                cookies. However same will not delete any data that have been
                downloaded /collected in past, and we may still use the data
                collected up to that point of action of deleting or turning off.
                However upon such deleting or turning off we will stop
                collecting new data.
                <br />
                You can turn off or delete cookies in your browser. If you do
                this, it may affect Website or our certain services and also
                affect Websites that use similar cookies. Some of our cookies
                collect data for more than one use. We will use these cookies
                only for essential purposes, unless you have given your consent
                to any other use they have.
              </p>

              <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>
                {" "}
                7. Controlling and deleting cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                On you first visit or visits you may see a pop-up to inform you
                that cookies are being used. Although this pop-up may not
                usually appear on subsequent visits. You may withdraw your
                cookie consent at any time by changing your browser or device
                setting.
                <br />
                You can set up your browser / device setting to delete or refuse
                some or all the cookies, or to be notified when you are sent a
                cookies and therefore, choose whether or not to accept it. You
                may delete or refuse, some or all of the cookies, on our Website
                any time.
                <br />
                You may also opt out of Third Parties cookies by following the
                instructions provided by each Third Party in its Privacy Policy.
                <br />
                Unless you have adjusted your browser setting so that they
                delete or refuse cookies, cookies will be issued or reissued
                when you direct your browser to our Website. If you do delete or
                refuse cookies, but subsequently decide to allow them, you
                should adjust your browser setting and continue using our
                Website.
                <br />
                If you do delete some or all your cookies, some functionality on
                our Website may be disables and as result you may not be able to
                access certain part or feature of our Website and your browsing
                experience of our Website may be affected.
                <br />
                To find out more about cookies (including that of managing and
                deleting them) you may visit www.allaboutcookies.org.
                Alternatively you may search internet for other independent
                information on cookies.
                <br />
              </p>
              <h6 style={{ color: APP_COLORS.APP_DARK_1 }}>
                8. For knowing other use of Personal data you may visit our
                Privacy policy.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
